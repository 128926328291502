import { Navigation } from './navigation.service';

export interface NavStructure {
  [role: string]: Navigation[];
}

export const NAV: NavStructure = {
  DEFAULT: [
    {
      id: 'scoring',
      title: 'Scoring',
      type: 'collapsable',
      show_item: false,
      children: [
        {
          id: 'bouquet_management',
          title: 'Ramos',
          type: 'item',
          url: '/main/bouquet-management',
          icon: 'all_inbox',
        },
        {
          id: 'product_management',
          title: 'Productos',
          type: 'item',
          url: '/main/product-management',
          icon: 'assignment',
        },
        {
          id: 'classification_management',
          title: 'Clasificaciones',
          type: 'item',
          url: '/main/classification-management',
          icon: 'list_alt',
        },
        {
          id: 'variable_management',
          title: 'Variables',
          type: 'item',
          url: '/main/variable-management',
          icon: 'receipt',
        },
        {
          id: 'comparative_values_​​management',
          title: 'Valores',
          type: 'item',
          url: '/main/comparative-values',
          icon: 'airplay',
        },
        {
          id: 'simple_rule',
          title: 'Reglas Simples',
          type: 'item',
          url: '/main/simple-rules-management',
          icon: 'storage',
        },
        {
          id: 'complex_rule',
          title: 'Reglas Complejas',
          type: 'item',
          url: '/main/complex-rules-management',
          icon: 'toc',
        },
      ],
    },
    {
      id: 'sarlaft',
      title: 'Sarlaft',
      type: 'collapsable',
      show_item: false,
      children: [
        {
          id: 'parametrization',
          title: 'Parametrización',
          type: 'item',
          url: '/sarlaft/parametrization',
          icon: 'settings_applications',
        },
        {
          id: 'report',
          title: 'Reportes',
          type: 'item',
          url: '/sarlaft/report',
          icon: 'add_chart',
        },
        {
          id: 'logs',
          title: 'Logs',
          type: 'item',
          url: '/sarlaft/logs',
          icon: 'update',
        },
      ],
    },
  ],
};
