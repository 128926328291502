import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorCustomized extends MatPaginatorIntl {
  constructor() {
    super();
    this.itemsPerPageLabel = 'Item por página';
  }
}
