import { version } from '../../package.json';

// environment variable setting
export const environment = {
  production: false,
  version: version,
  host: 'https://apimotor.globalseguros.co',
  sarlaftHost: 'https://apisarlaft.globalseguros.co',
  whiteListDomains: [
    'apimotor.globalseguros.co',
    'apisarlaft.globalseguros.co',
  ],
  blackListRoutes: ['/v1/users/refresh/'],
  property: false,
};
