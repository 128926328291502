import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { parseJwt } from '@utils/functions';
import { environment } from '@environments/environment';
import { NAV } from '../navigation/navigation.constant';
import { Credentials, JWTDecoded, TokenResponse } from './auth.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly scoringTokenName = 'scoring_access_token';
  private readonly scoringRefreshToken = 'scoring_refresh_token';

  private readonly sarlaftTokenName = 'sarlaft_access_token';
  private readonly sarlaftRefreshToken = 'sarlaft_refresh_token';

  private readonly ExpTokenTime = 'access_expiration_time';
  private readonly ExpRefreshTokenTime = 'refresh_expiration_time';

  private readonly login_time = 'login_time';

  private readonly authenticationType = 'Bearer';
  private readonly loginRoute = '/account/sign-in';
  private readonly loginEndpoint = '/v1/users/login/';

  private $session: BehaviorSubject<JWTDecoded> = new BehaviorSubject(null);
  private $sarlaftSession: BehaviorSubject<JWTDecoded> = new BehaviorSubject(
    null
  );

  public readonly session: Observable<JWTDecoded> =
    this.$session.asObservable();
  public readonly sarlaftSession: Observable<JWTDecoded> =
    this.$sarlaftSession.asObservable();

  /**
   * @name Constructor
   * @param http
   * @param router
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog_ref: MatDialog
  ) {}

  //  ------------------------------------------------------------------------------------------------
  //  Get methods
  //  ------------------------------------------------------------------------------------------------

  /**
   * @name setAccessToken
   * @param accessToken access token to be saved
   */
  public get getAuthenticationType(): String {
    return this.authenticationType;
  }
  /**
   * Gets the access token of Scoring Motor from LocalStorage
   * @returns An access token
   */
  public get getAccessTokenScoring(): string {
    return localStorage.getItem(this.scoringTokenName);
  }
  /**
   * Gets the access token of Sarlaft Motor from LocalStorage
   * @returns An access token
   */
  public get getAccessTokenSarlaft(): string {
    return localStorage.getItem(this.sarlaftTokenName);
  }
  /**
   * @name getRefreshToken
   * @type string
   * @description Gets the refresh token from LocalStorage
   */
  public get getRefreshScoringToken(): string {
    return localStorage.getItem(this.scoringRefreshToken);
  }
  /**
   * @name getRefreshSarlaftToken
   * @type string
   * @description Gets the refresh token from LocalStorage
   */
  public get getRefreshSarlaftToken(): string {
    return localStorage.getItem(this.sarlaftRefreshToken);
  }
  /**
   * Get expiration token time from LocalStorage
   * @returns An time
   */
  public get getExpTokenTime(): string {
    return localStorage.getItem(this.ExpTokenTime);
  }
  /**
   * Get expiration refresh token time from LocalStorage
   * @returns An time
   */
  public get getExpRefreshTokenTime(): string {
    return localStorage.getItem(this.ExpRefreshTokenTime);
  }
  /**
   * Get login time from LocalStorage
   * @returns An time
   */
  public get getLoginTime(): string {
    return localStorage.getItem(this.login_time);
  }

  //  ------------------------------------------------------------------------------------------------
  //  Set methods
  //  ------------------------------------------------------------------------------------------------

  /**
   * @name setAccessTokenScoring
   * @param accessToken access token to be saved
   */
  public setAccessTokenScoring(accessToken): void {
    localStorage.setItem(this.scoringTokenName, accessToken);
  }
  /**
   * @name setAccessTokenSarlaft
   * @param accessToken access token to be saved
   */
  public setAccessTokenSarlaft(accessToken): void {
    localStorage.setItem(this.sarlaftTokenName, accessToken);
  }
  /**
   * @name setRefreshToken
   * @type void
   * @param refreshToken Refresh token to be saved
   */
  public setRefreshScoringToken(refreshToken): void {
    localStorage.setItem(this.scoringRefreshToken, refreshToken);
  }
  /**
   * @name setRefreshSarlaftToken
   * @type void
   * @param refreshToken Refresh token to be saved
   */
  public setRefreshSarlaftToken(refreshToken): void {
    localStorage.setItem(this.sarlaftRefreshToken, refreshToken);
  }
  /**
   * @name setExpTokenTime
   * @type void
   * @param expiration_time expiration token time to be saved
   */
  public setExpTokenTime(expiration_time: string): void {
    localStorage.setItem(this.ExpTokenTime, expiration_time);
  }
  /**
   * @name setExpRefreshTokenTime
   * @param expiration_time expiration refresh token time to be saved
   */
  public setExpRefreshTokenTime(expiration_time: string): void {
    localStorage.setItem(this.ExpRefreshTokenTime, expiration_time);
  }
  /**
   * Set login time from LocalStorage
   * @returns An time
   */
  public setLoginTime(login_time: string): void {
    localStorage.setItem(this.login_time, login_time);
  }

  //  ------------------------------------------------------------------------------------------------
  //  Remove methods
  //  ------------------------------------------------------------------------------------------------

  /**
   * @name removeAccessToken
   * @type void
   * @description Remove access token from the backend and localStorage
   */
  public async removeAccessTokenScoring(
    close_session?: boolean
  ): Promise<void> {
    // validate if exist access token
    if (this.getAccessTokenScoring) {
      // validate close session property
      if (close_session) {
        await this.http
          .delete(`${environment.host}/v1/users/logout/`)
          .toPromise();
      }
      // Remove token from local storage
      localStorage.removeItem(this.scoringTokenName);
    }
  }
  /**
   * @name removeAccessToken
   * @type void
   * @description Remove access token from the backend and localStorage
   */
  public async removeAccessTokenSarlaft(
    close_session?: boolean
  ): Promise<void> {
    // validate if exist access token
    if (this.getAccessTokenSarlaft) {
      // validate close session property
      if (close_session) {
        await this.http
          .delete(`${environment.sarlaftHost}/v1/users/logout/`)
          .toPromise();
      }
      // Remove token from local storage
      localStorage.removeItem(this.sarlaftTokenName);
    }
  }
  /**
   * @name removeRefreshToken
   * @type void
   * @description Remove refresh token from LocalStorage
   */
  public removeRefreshScoringToken(): void {
    localStorage.removeItem(this.scoringRefreshToken);
  }
  /**
   * @name removeRefreshSarlaftToken
   * @type void
   * @description Remove refresh token from LocalStorage
   */
  public removeRefreshSarlaftToken(): void {
    localStorage.removeItem(this.sarlaftRefreshToken);
  }
  /**
   * @name removeExpTokenTime
   * @type void
   * @description Remove expiration token time from LocalStorage
   */
  public removeExpTokenTime(): void {
    localStorage.removeItem(this.ExpTokenTime);
  }
  /**
   * @name removeExpRefreshTokenTime
   * @description Remove expiration refresh token time from the localStorage
   */
  public removeExpRefreshTokenTime(): void {
    localStorage.removeItem(this.ExpRefreshTokenTime);
  }
  /**
   * @name removeLoginTime
   * @description Remove login time from the localStorage
   */
  public removeLoginTime(): void {
    localStorage.removeItem(this.login_time);
  }

  //  ------------------------------------------------------------------------------------------------
  //  Private methods
  //  ------------------------------------------------------------------------------------------------

  /**
   * Do login logic for the app. Here we don't do request to server.
   * 1. Receive `credentials` params
   * 2. Call `login` method
   * 3. Save `access-token` and `refresh-token` if they exists
   * @param credentials
   */
  public doScoringLogin(credentials: Credentials): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.login(credentials, environment.host).subscribe(
        (result) => {
          if (result) {
            // save access token
            this.setAccessTokenScoring(result.access.token);
            // save refresh token
            this.setRefreshScoringToken(result.refresh.token);
            // save current times
            this.setTimes(result);
            // resolve
            resolve(true);
            // return
            return;
          }
          // error message
          throw 'We could not get access token';
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Do login logic for the app. Here we don't do request to server.
   * 1. Receive `credentials` params
   * 2. Call `login` method
   * 3. Save `access-token` and `refresh-token` if they exists
   * @param credentials
   */
  public doSarlaftLogin(credentials: Credentials): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.login(credentials, environment.sarlaftHost).subscribe(
        (result) => {
          if (result) {
            // save access token
            this.setAccessTokenSarlaft(result.access.token);
            // save refresh token
            this.setRefreshSarlaftToken(result.refresh.token);
            // save current times
            this.setTimes(result);
            // resolve
            resolve(true);
            // resolve
            return;
          }
          // error message
          throw 'We could not get access token';
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * refresh scoring token
   * @param url
   * @returns boolean property
   */
  public onRefreshScoringToken(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.refreshToken(url, this.getRefreshScoringToken).subscribe(
        (result) => {
          if (result) {
            // save access token
            this.setAccessTokenScoring(result.access.token);
            // save refresh token
            this.setRefreshScoringToken(result.refresh.token);
            // save current times
            this.setTimes(result);
            // resolve
            resolve(true);
          }
        },
        (error) => {
          resolve(false);
        }
      );
    });
  }

  /**
   * refresh sarlaft token
   * @param url
   * @returns boolean property
   */
  public onRefreshSarlaftToken(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.refreshToken(url, this.getRefreshSarlaftToken).subscribe(
        (result: TokenResponse) => {
          if (result) {
            // save access token
            this.setAccessTokenSarlaft(result.access.token);
            // save refresh token
            this.setRefreshSarlaftToken(result.refresh.token);
            // save current times
            this.setTimes(result);
            // resolve
            resolve(true);
          }
        },
        (error) => resolve(false)
      );
    });
  }

  /**
   * Request a refresh token to server.
   * @param credentials
   */
  private refreshToken(
    url: string,
    refresh_token: string
  ): Observable<TokenResponse> {
    return this.http.get<TokenResponse>(`${url}/v1/users/refresh/`, {
      headers: { authorization: `${this.authenticationType} ${refresh_token}` },
    });
  }

  /**
   * Request a Login to server. Passing user credentials
   * @param credentials
   */
  private login(
    credentials: Credentials,
    host: string
  ): Observable<TokenResponse> {
    // add word 'dev/' of environment.dev
    const property = environment.property;
    // concatenate values
    const login_form = { ...credentials, dev: property };

    return this.http
      .post<TokenResponse>(`${host}${this.loginEndpoint}`, login_form)
      .pipe(
        retry(2),
        catchError((error) => {
          throw error;
        })
      );
  }

  /**
   * @name setTimes
   * save ExpTokenTime, ExpRefreshTokenTime and LoginTime
   * @param data
   */
  private setTimes(data: TokenResponse): void {
    // save expiration token time
    this.setExpTokenTime(data.access.expiration_time.toString());
    // save expiration refresh token time
    this.setExpRefreshTokenTime(data.refresh.expiration_time.toString());
    // save login time
    this.setLoginTime(new Date().getTime().toString());
  }
  /**
   * Check user authentication.
   * 1. Try to return user data on memory if exists
   * 2. else try to get data from server using access-token on localStorage.
   * 3. Redirect to login if everything fail
   */
  public checkScoringAuth(): Promise<JWTDecoded> {
    return new Promise((resolve) => {
      let session = this.$session.getValue();
      if (session) {
        // enable menu
        this.enableMenu(session);
        // resolve
        resolve(session);
      } else if (this.getAccessTokenScoring) {
        this.$session.next(parseJwt(this.getAccessTokenScoring));
        // enable menu
        this.enableMenu(this.$session.getValue());
        // resolve
        resolve(this.$session.getValue());
      } else {
        this.logout();
      }
    });
  }

  /**
   * Check user authentication.
   * 1. Try to return user data on memory if exists
   * 2. else try to get data from server using access-token on localStorage.
   * 3. Redirect to login if everything fail
   */
  public checkSarlaftAuth(): Promise<JWTDecoded> {
    return new Promise((resolve) => {
      let session = this.$sarlaftSession.getValue();
      if (session) {
        // enable menu
        this.enableMenu(session);
        // resolve
        resolve(session);
      } else if (this.getAccessTokenSarlaft) {
        this.$sarlaftSession.next(parseJwt(this.getAccessTokenSarlaft));
        // enable menu
        this.enableMenu(this.$sarlaftSession.getValue());
        // resolve
        resolve(this.$sarlaftSession.getValue());
      } else {
        this.logout();
      }
    });
  }

  /**
   * @name enableMenu
   * @param session (required)
   * set profiling type
   */
  public enableMenu(session: JWTDecoded): void {
    if (session.sub.scoring_admin || session.sub.scoring_viewer) {
      let admin = session.sub.scoring_admin;
      let viewer = session.sub.scoring_viewer;

      admin
        ? (NAV.DEFAULT[0].show_item = admin)
        : (NAV.DEFAULT[0].show_item = viewer);
    }

    if (session.sub.sarlaft_admin || session.sub.sarlaft_viewer) {
      let admin = session.sub.sarlaft_admin;
      let viewer = session.sub.sarlaft_viewer;

      admin
        ? (NAV.DEFAULT[1].show_item = admin)
        : (NAV.DEFAULT[1].show_item = viewer);
    }
  }

  /**
   * @name logout
   * @param close_session validate manual logout
   * @description Remove all user metadata and redirect to login page.
   */
  public logout(close_session?: boolean) {
    // reset session info
    this.$session.next(null);
    this.$sarlaftSession.next(null);
    // reset menu
    NAV.DEFAULT[0].show_item = false;
    NAV.DEFAULT[1].show_item = false;
    // remove access token from local storage
    this.removeAccessTokenScoring(close_session);
    this.removeAccessTokenSarlaft(close_session);
    // remove refresh token from local storage
    this.removeRefreshScoringToken();
    this.removeRefreshSarlaftToken();
    // remove idle time
    this.removeExpTokenTime();
    this.removeExpRefreshTokenTime();
    this.removeLoginTime();
    // close all open dialogs
    this.dialog_ref.closeAll();
    // got o login
    this.router.navigate([this.loginRoute]);
  }
}
