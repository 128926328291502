import { BouquetData } from 'app/main/bouquet-management/bouquet.model';
import { ProductData } from 'app/main/product-management/product.model';
import { ValuesData } from 'app/main/values-management/values.model';
import { DataTypeList } from 'app/main/variable-management/variable-management.model';
import { VariableData } from 'app/sarlaft/parameterization/parameterization.model';
import { ArrayData } from './general-interface';

export const parseJsonParams = (objectparams) => {
  for (const key in objectparams) {
    if (objectparams.hasOwnProperty(key)) {
      let value = objectparams[key];

      if (
        (value !== false && value === '') ||
        value == null ||
        value == 'null' ||
        value === undefined
      ) {
        delete objectparams[key];
        continue;
      }
    }
  }
  return objectparams;
};

export const requiredParameter = (parameter: any, params_name: string) => {
  if (!parameter) throw Error(`${params_name} requerido`);
};

export const filterData = (data_list: Array<any>) => {
  // return list of unarchived data
  return data_list.filter((element) => !element.archived);
};
/**
 * dataTypeReduce
 * @param datatype_list
 */
export const dataTypeReduce = (datatype_list: Array<DataTypeList>) => {
  // save list of id
  // Booleano: 1, Entero: 2, Decimal: 3, Texto: 4, Fecha: 5, Hora: 6, Fecha y Hora: 7, Lista: 8
  let datatype = datatype_list.reduce((result, item) => {
    result[item.name] = item.id;
    return result;
  }, {});
  return datatype;
};

export const settingValue = (
  data: ValuesData,
  datatype_list: Array<DataTypeList>
) => {
  // assign values from data
  let new_data = data;
  // save list of id
  let datatype = dataTypeReduce(datatype_list);

  if (!!new_data.values?.length) {
    let data_list = [];
    new_data.values.map((item) => {
      if (item) {
        if (data.data_type_id == datatype['Booleano']) {
          new_data['values'] = item.value_text.toLowerCase();
        }

        if (
          data.data_type_id >= datatype['Entero'] &&
          data.data_type_id <= datatype['Texto']
        ) {
          new_data['values'] = item.value_text;
        }

        if (data.data_type_id == datatype['Fecha']) {
          new_data['values'] = new Date(item.value_text);
        }

        if (data.data_type_id == datatype['Hora']) {
          new_data['values'] = new Date(item.value_text).toTimeString();
        }

        if (data.data_type_id == datatype['Fecha y Hora']) {
          new_data['values'] = new Date(item.value_text).toLocaleString();
        }

        if (data.data_type_id == datatype['Lista']) {
          data_list.push(item.value_text);
        }
      }
    });
    if (!!data_list.length) {
      new_data['values'] = data_list;
    }
  }
  return new_data;
};

export const filterBanksMulti = (
  list: ArrayData[],
  filter_ctrl: string
): Array<ArrayData> => {
  let list_filtered = list;
  if (!list) {
    return;
  }
  // get the search keyword
  let search = filter_ctrl;
  if (!search) {
    list_filtered = list.slice();
    return list_filtered;
  } else {
    search = search.toLowerCase();
  }
  // filter the banks
  return (list_filtered = list.filter((item) => {
    if (item.name.toLowerCase().indexOf(search) > -1) {
      return item;
    }
  }));
};

/**
 * @param list (required)
 * disable inputs elements
 */
export const disableInputs = (list: HTMLCollectionOf<Element>): void => {
  for (let i = 0; i < list.length; i++) {
    let current_element = list[i] as HTMLInputElement;
    console.log(current_element);

    current_element.disabled = true;
  }
};

/**
 * get data source and return index row
 * @param data_source (required)
 * @param row (required)
 * @returns index row or -1 if not found
 */
export const getIndexRow = (data_source: VariableData[], row: VariableData) => {
  return data_source.indexOf(row);
};

/**
 * @param token
 * @returns
 */
export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

/**
 * @name getDateTime
 * @returns date time
 */
export const getDateTime = () => {
  let date_time = new Date().getTime();
  return date_time;
};

/**
 * @name firstUpperCase
 * @param label
 * @returns text with initial capital letter
 */
export const firstUpperCase = (label: string) => {
  if (label) {
    let str = label.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return label;
};
