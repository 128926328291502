import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler } from '@angular/common/http';
import { HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  private AuthHeaderName = 'Authorization';

  private whiteListDomains: String[] = environment.whiteListDomains;
  private blackListRoutes: string[] = environment.blackListRoutes;

  /**
   * @name constructor
   * @param _auth
   */
  constructor(private _auth: AuthService) {}

  /**
   * capture request to add token in request header
   *
   * @param request (required)
   * @param next (required)
   * @returns Observable<HttpEvent<any>>
   */
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  private async handle(request: HttpRequest<any>, next: HttpHandler) {
    // Request url
    let requestUrl = new URL(request.url);
    // Request copy
    let copyRequest = null;
    // validate requestUrl in whiteListDomains
    if (
      this.isIncludedInWhiteList(requestUrl) &&
      !this.isIncludedInBlackRoutes(requestUrl)
    ) {
      // header object
      let headerValue = {};
      // scoring host
      let scoringHost = new URL(environment.host).host;
      // sarlaft host
      let sarlaftHost = new URL(environment.sarlaftHost).host;
      // current date time
      let current_date_time = new Date().getTime();
      // login time
      let login_time = parseInt(this._auth.getLoginTime);
      // expiration token time
      let exp_token_time = parseInt(this._auth.getExpTokenTime);

      // validate bag token time
      if (current_date_time - login_time >= exp_token_time) {
        // validate access token
        if (this._auth.getAccessTokenScoring) {
          let refresh_scoring = await this._auth.onRefreshScoringToken(
            environment.host
          );
          if (!refresh_scoring) this._auth.logout();
        }
        // validate access token
        if (this._auth.getAccessTokenSarlaft) {
          let refresh_sarlaft = await this._auth.onRefreshSarlaftToken(
            environment.sarlaftHost
          );
          if (!refresh_sarlaft) this._auth.logout();
        }
      }
      // token reference object
      let accessTokenRef = {
        [scoringHost]: this._auth.getAccessTokenScoring,
        [sarlaftHost]: this._auth.getAccessTokenSarlaft,
      };
      // If value exist on localStorage, use it
      if (accessTokenRef[requestUrl.host]) {
        headerValue[this.AuthHeaderName] = `${
          this._auth.getAuthenticationType
        } ${accessTokenRef[requestUrl.host]}`;
      }

      // clone header value
      copyRequest = request.clone({ setHeaders: headerValue });
    } else {
      copyRequest = request;
    }

    return next.handle(copyRequest).toPromise();
  }

  /**
   * Check if domain is included on white list.
   * @param requestUrl
   * @returns boolean
   */
  private isIncludedInWhiteList(requestUrl: URL) {
    try {
      return (
        this.whiteListDomains.findIndex((domain) => {
          return typeof domain === 'string'
            ? domain === requestUrl.host
            : domain instanceof RegExp
            ? domain.test(requestUrl.host)
            : false;
        }) > -1
      );
    } catch (err) {
      // If we're here, the request will be made configuration as the Angular app.
      return true;
    }
  }

  /**
   * Check if pathname e.g. `/account/login/` is included in Black routes.
   * @param requestUrl (required)
   * @returns boolean
   */
  private isIncludedInBlackRoutes(requestUrl: URL) {
    return (
      this.blackListRoutes.findIndex(
        (path) => requestUrl.pathname.indexOf(path) >= 0
      ) > -1
    );
  }
}
