import { NgModule } from '@angular/core';
import { MaterialModules } from './material';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { DateStringPipe } from './pipes/date-string.pipe';
import { InputSearchDirective } from './directives/input-search';
import { FormControlErrorPipe } from './pipes/form-control-error.pipe';
import { MsgBoxComponent } from './components/msg-box/msg-box.component';
import { LayoutFullComponent } from './layouts/layout-full/layout-full.component';
import { LayoutBlankComponent } from './layouts/layout-blank/layout-blank.component';
import { NavbarComponent } from './layouts/layout-components/navbar/navbar.component';
import { LayoutSimpleComponent } from './layouts/layout-simple/layout-simple.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { StatusTableComponent } from './components/status-table/status-table.component';
import { SidebarComponent } from './layouts/layout-components/sidebar/sidebar.component';
import { NavItemComponent } from './layouts/layout-components/sidebar/nav-item/nav-item.component';
import { RulesDetailTableComponent } from './components/rules-detail-table/rules-detail-table.component';
import { NavCollapsableComponent } from './layouts/layout-components/sidebar/nav-collapsable/nav-collapsable.component';
import { NoDataTableComponent } from './components/no-data-table/no-data-table.component';

@NgModule({
  declarations: [
    MsgBoxComponent,
    NavbarComponent,
    SidebarComponent,
    NavItemComponent,
    LayoutFullComponent,
    LayoutBlankComponent,
    ProgressBarComponent,
    StatusTableComponent,
    LayoutSimpleComponent,
    NavCollapsableComponent,
    RulesDetailTableComponent,
    // pipes
    DateStringPipe,
    FormControlErrorPipe,
    // Directives
    InputSearchDirective,
    NoDataTableComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    FormsModule,
    MaterialModules,
    FlexLayoutModule,
    MatExpansionModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  exports: [
    // PIPES
    DateStringPipe,
    FormControlErrorPipe,
    // LAYOUTS
    LayoutFullComponent,
    LayoutSimpleComponent,
    LayoutBlankComponent,
    ProgressBarComponent,
    NoDataTableComponent,
    StatusTableComponent,
    RulesDetailTableComponent,
    // OTHERS
    FormsModule,
    MaterialModules,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    // Directives
    InputSearchDirective,
  ],
})
export class SharedModule {}
